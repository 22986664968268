import config from "./config";

export const WEBNAME = config.webName;
export const FACEBOOK = config.facebook;
export const INSTERGRAM = config.instergram;
export const ADDRESS = config.address;
export const EMAIL = config.email;
export const WHATSAPP = config.whatsapp;
export const WEB_LINK = config.webLink;
export const SERVER_LINK = config.server;
export const IMAGE_URL = config.imagepath;
export const NO_IMAGE = config.noImage;
export const DEVS_SITE = config.devsSite;
export const YT_TUTORIAL = config.yttute;
export const YT_TUTORIAL_GUIDE = config.yttuteGuide;
export const SERVER_API = config.serverapi;

//web route
export const REGISTERURL = config.registerUrl;
export const BOOKINGURL = config.bookingUrl;
export const HOMEPAGEURL = config.homepageurl;
export const GALLERYPAGEURL = config.gallerypageurl;
export const SEARCHGUIDE = config.searchGuidegeurl;
export const SEARCHHOTEL = config.searchHotelurl;
export const TERMSPAGEURL = config.termspageurl;
export const ABOUTAGEURL = config.abouturl;
export const CONTACTPAGEURL = config.contacturl;
export const GUIDEPUBLICPROFILE = config.guidepublicprofile;
export const HOTELPUBLICPROFILE = config.hotelpublicprofile;
export const AUTH_PAGE = config.auth;
export const LOGING_SUCCESS = config.logingsuccess;
export const PAGENOTFOUND = config.pagenotfound;
export const DASHBOARD = config.dashboard;
export const INBOX = config.inbox;

//guide routs
export const GUIDE_TERMS_AND_CONDITION = config.guidetermspageurl;

//admin
export const ADMINUSERMANAGE = config.manage;
export const ADMINACCOUNTS = config.accounts;
export const ADMIN_GUIDE_MANAGE = config.guidemanage;

//payment
export const FIRSTPAYMENT = config.firstPayment;
export const SECONDPAYMENT = config.secondPayment;
export const COMMISSION = config.commission;
export const CURRENCY = config.currency;

//image
export const IMAGE_SIZE = 1048576; // 1MB = 1048576 bytes
export const MSG_REFETCHING_SECONDS = 20000;
export const languageLevels = [
  "Beginner",
  "Intermediate",
  "Advanced",
  "Proficient",
  "Native",
];

export const countries = [{ value: "SRI LANKA", label: "Sri Lanka" }];

export const guideTypes = [
  { value: "National", label: "National" },
  { value: "Chauffeur", label: "Chauffeur" },
  { value: "Authorized Tourist Driver", label: "Authorized Tourist Driver" },
];

export const languages = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Chinese", label: "Chinese" },
];

// genderOptions.js
export const genderOptions = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
  { value: "PREFER_NOT_TO_SAY", label: "Prefer not to say" },
];
