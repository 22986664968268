import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetHotelFacilityData = async () => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Hotel/get-hotel-facility-data.php`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};
