import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const LoadingScreen = ({ message }) => (
  <div className="flex justify-center flex-col gap-y-10 items-center h-screen">
    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    <p>{message}</p>
  </div>
);
