import { Card, List } from "antd";
import React from "react";

export const HotelDetails = ({ hotelData }: any) => {
  return (
    <Card title="Hotel Information" className="shadow-lg mx-0 px-3 my-3">
      <List>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-person-lines-fill" />
            Hotel category : {hotelData?.category}
          </span>
        </List.Item>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-coin" />
            Rate :{" "}
            {hotelData?.rating} stars
          </span>
        </List.Item>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-passport" />
            Map : <a href={hotelData?.mapLink}>Click</a>
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-at" />
            Location : {hotelData?.location}
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-person-check" />
            Verified Tourist hotel
          </span>
        </List.Item>
      </List>
    </Card>
  );
};
