import React, { useEffect, useState } from "react";
import { Drawer, Input, Spin } from "antd";
import { Inbox, InboxList, LoadingScreen } from "../../../../src/components";
import { useParams } from "react-router-dom";
import { useAuthCheck } from "../../../../src/Hook";
import { GetAllBookingInbox } from "../../../../src/Api";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { BookingDetails } from "../components/Booking-details";

export const AdminDashBoard = () => {
  const { userid } = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { isAuthenticated, isLoading } = useAuthCheck(userid);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [inboxMsgList, setInboxMsgList] = useState<any>();
  const [isInboxMsgListLoading, setIsInboxMsgListLoading] =
    useState<any>(false);
  const [searchText, setSearchText] = useState("");
  const [bookigDrawerVisible, setBookingDrawerVisible] = useState(false);
  const [inboxDrawerVisible, setInboxDrawerVisible] = useState(false);

  const fetchInboxMsgListData = async () => {
    setIsInboxMsgListLoading(true);
    try {
      const inboxData = await GetAllBookingInbox();
      if (inboxData && inboxData.isDataSet) {
        setInboxMsgList(inboxData.inbox);
        setSelectedMessageId(inboxData.inbox[0]?.id);
      }
    } catch (error) {
      toast.error("Somyhing went wrong!");
    } finally {
      setIsInboxMsgListLoading(false);
    }
  };

  const handleSelectMessage = (id) => {
    setSelectedMessageId(id);
  };

  //inbox msg list search
  const filteredInboxMsgList = inboxMsgList?.filter((message) =>
    message.bookingid.toLowerCase().includes(searchText.toLowerCase())
  );
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  useEffect(() => {
    if (userid) {
      fetchInboxMsgListData();
    }
  }, [userid]);

  const showBookingDrawer = () => {
    setBookingDrawerVisible(true);
  };

  const onBookingClose = () => {
    setBookingDrawerVisible(false);
  };

  const showInboxDrawer = () => {
    setInboxDrawerVisible(true);
  };

  const onInboxClose = () => {
    setInboxDrawerVisible(false);
  };

  if (isLoading)
    return <LoadingScreen message="Page is loading. Please wait a moment." />;
  if (!isAuthenticated) return <LoadingScreen message="Redirecting..." />;

  return (
    <>
      <div className="flex">
        <div className="h-[90vh] w-full md:w-[30vw] flex flex-col px-3">
          <span style={{ zIndex: -1 }}>
            <Input.Search
              placeholder="Search booking id"
              onKeyUp={handleKeyUp}
              className="w-full z-1 max-w-lg mb-4"
              size="large"
            />
          </span>
          <div className="w-full max-w-lg bg-white shadow-lg rounded-lg py-4 overflow-y-auto">
            <InboxList
              isInboxMsgListLoading={isInboxMsgListLoading}
              inboxMsgList={filteredInboxMsgList}
              selectedMessageId={selectedMessageId}
              handleSelectMessage={handleSelectMessage}
              showBookingDrawer={showBookingDrawer}
              showInboxDrawer={showInboxDrawer}
            />
          </div>
        </div>
        <div className="w-[70vw] hidden md:block h-[90vh] overflow-y-scroll overflow-x-hidden">
          <BookingDetails selectedMessageId={selectedMessageId} />
        </div>
      </div>

      <Drawer
        title="Booking Details"
        placement="right"
        closable={true}
        onClose={onBookingClose}
        visible={isMobile && bookigDrawerVisible}
        width={500}
      >
        <div className="w-full bg-white shadow-lg rounded-lg">
          <BookingDetails selectedMessageId={selectedMessageId} />
        </div>
      </Drawer>

      <Drawer
        title="Inbox"
        placement="right"
        closable={true}
        onClose={onInboxClose}
        visible={inboxDrawerVisible}
        width={500}
      >
        <Inbox
          selectedMessageId={selectedMessageId}
          isDrawerOpen={inboxDrawerVisible}
        />
      </Drawer>
    </>
  );
};
