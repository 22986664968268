import React from "react";
import { Card, List } from "antd";
import { COMMISSION } from "../../../Constant";

const GuideDetails = ({ guideData }) => {

  return (
    <Card title="Guide Information" className="shadow-lg mx-0 px-0">
      <List>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-person-lines-fill" />
            Guide Type : {guideData?.guidetype}
          </span>
        </List.Item>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-coin" />
            Day Rate :{" "}
            <strong>${(Number(guideData?.price) * COMMISSION).toFixed(2)}</strong>
          </span>
        </List.Item>
        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-passport" />
            Experience : {guideData?.experiencedYear} (yrs)
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-at" />
            Country : {guideData?.country}
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-person-check" />
            Verified Tour Guide
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-suitcase2-fill" />
            Total Tours with Us : {guideData?.totalTour || 0}
          </span>
        </List.Item>

        <List.Item className="flex justify-start">
          <span className="flex">
            <i className="mr-2 bi bi-translate" />
            Languages
          </span>
        </List.Item>
        <div>
          <ul className="list-none list-inside ml-1">
            {guideData?.languages.map((lang, index) => (
              <li key={index} className="font-bold">
                {lang.language} ({lang.level})
              </li>
            ))}
          </ul>
        </div>
      </List>
    </Card>
  );
};

export default GuideDetails;
