import { SendOutlined } from "@ant-design/icons";
import { useMediaQuery } from "@mui/material";
import { Button, Drawer, DatePicker, Input, Form, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { COMMISSION, IBookingStatus } from "../../Constant";
import { useParams } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { toast } from "react-toastify";
import { AddBookingData } from "../../Api";

const { Option } = Select;

export const GuideBookingReqCard = ({ guideData }: any) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs] | null>(
    null
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [daysBetween, setDaysBetween] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const { user, isAuthenticated } = useKindeAuth();
  const { userid } = useParams();

  const isMobile = useMediaQuery("(max-width:600px)");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
    calculateDaysBetween(date, endDate);
  };

  const onEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
    calculateDaysBetween(startDate, date);
  };

  const calculateDaysBetween = (start: Dayjs | null, end: Dayjs | null) => {
    if (start && end) {
      const daysDifference = end.diff(start, "day");

      if (daysDifference >= 0) {
        setDaysBetween(daysDifference + 1); // Add 1 to include both start and end dates
      } else {
        toast.error("End date must be after the start date.");
        setDaysBetween(0);
      }
    } else {
      setDaysBetween(0);
    }
  };

  const handlePackageChange = (value: string) => {
    setSelectedPackage(value);
  };

  const calculateTotalCost = () => {
    let total = 0;

    if (guideData?.price && daysBetween > 0) {
      total += guideData.price * daysBetween * COMMISSION;
    }

    if (selectedVehicle) {
      const vehicle = guideData?.userVehicleData?.find(
        (vcl) => vcl.id === selectedVehicle
      );
      if (vehicle) {
        total += vehicle.price * daysBetween * COMMISSION;
      }
    }

    if (selectedPackage) {
      const pack = guideData?.userPackageData?.find(
        (pck) => pck.id === selectedPackage
      );
      if (pack) {
        total += pack.price * COMMISSION;
      }
    }
    setTotalCost(total);
  };

  useEffect(() => {
    calculateTotalCost();
  }, [selectedPackage, selectedVehicle, daysBetween]);

  const onFinish = async (values: any) => {
    if (isAuthenticated && user?.id) {
      const data = {
        bookinginfo: {
          ...values,
          daycount: daysBetween,
          guidecost: (guideData?.price * daysBetween * COMMISSION).toFixed(2),
          packagecost: (
            guideData?.userPackageData?.find((p) => p.id === selectedPackage)
              ?.price * COMMISSION || 0
          ).toFixed(2),
          vehiclecost: (
            guideData?.userVehicleData?.find((v) => v.id === selectedVehicle)
              ?.price *
              daysBetween *
              COMMISSION || 0
          ).toFixed(2),
          totalcost: totalCost.toFixed(2),
        },
        guideid: userid,
        guestid: user?.id,
        status: IBookingStatus.requested,
      };

      const response = await AddBookingData(data);

      if (response && response.isDataSet && response.isDataAdded) {
        toast.success(response.msg);
        onClose();
        setDaysBetween(0);
        setSelectedDates(null);
        setSelectedPackage(null)
        setTotalCost(0);
        form.resetFields();
      } else {
        toast.error(response.msg);
      }
    } else {
      toast.error("Please log first");
    }
  };

  const renderBookingCompo = () => {
    return (
      <div className="px-4">
        <Form
          form={form}
          id="booking-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <span className="text-md mb-2 py-0 text-gray-600">
            {daysBetween > 0 ? (
              <>
                {daysBetween} {daysBetween === 1 ? "Day" : "Days"} Selected
              </>
            ) : null}
          </span>

          <div className="flex justify-between my-0 py-0">
            <Form.Item
              label={`Select Start Date`}
              name="start_date"
              rules={[
                { required: true, message: "Please select a start date!" },
              ]}
            >
              <DatePicker
                onChange={onStartDateChange}
                value={startDate}
                size="large"
                disabledDate={(current) => {
                  return current && current < dayjs().startOf("day");
                }}
              />
            </Form.Item>

            <Form.Item
              label={`Select End Date`}
              name="end_date"
              rules={[
                { required: true, message: "Please select an end date!" },
              ]}
            >
              <DatePicker
                onChange={onEndDateChange}
                value={endDate}
                size="large"
                disabledDate={(current) => {
                  return current && current <= dayjs().startOf("day");
                }}
              />
            </Form.Item>
          </div>

          <Form.Item
            label="Number of Adults"
            name="adults_count"
            rules={[
              {
                required: true,
                message: "Please enter the number of adults!",
              },
            ]}
          >
            <Input type="number" min={1} placeholder="Enter number of adults" />
          </Form.Item>

          <Form.Item
            label="Number of Children"
            name="children_count"
            initialValue={0}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              type="number"
              min={0}
              placeholder="Enter number of children"
            />
          </Form.Item>

          <Form.Item label="Select Package" name="package">
            <Select
              placeholder="Select a package"
              onChange={handlePackageChange}
              value={selectedPackage}
              className="w-full"
            >
              <Option value={undefined}>Select None</Option>
              {guideData?.userPackageData?.map((pkg) => (
                <Option key={pkg.id} value={pkg.id}>
                  {pkg.name} - ${(pkg.price * COMMISSION).toFixed(2)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Select Vehicle" name="vehicle">
            <Select
              placeholder="Select a vehicle"
              onChange={setSelectedVehicle}
              value={selectedVehicle}
              className="w-full"
            >
              <Option value={undefined}>Select None</Option>
              {guideData?.userVehicleData?.map((vcl) => (
                <Option key={vcl.id} value={vcl.id}>
                  {vcl.name} - ${(vcl.price * COMMISSION).toFixed(2)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Select Language"
            name="language"
            rules={[{ required: true, message: "Please select a Language!" }]}
          >
            <Select
              placeholder="Select a Language"
              onChange={setSelectedLanguage}
              value={selectedLanguage}
              className="w-full"
            >
              <Option value={undefined}>Select None</Option>
              {guideData?.userLanguages?.map((lng) => (
                <Option key={lng.language} value={lng.language}>
                  {lng.language} - {lng.level}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Additional Note" name="note">
            <TextArea rows={4} placeholder="Enter any additional notes" />
          </Form.Item>

          <div className="my-4 p-4 bg-gray-100 rounded-lg shadow-md">
            {daysBetween != 0 && (
              <div className="flex justify-between items-center py-2">
                <span className="text-lg font-semibold text-gray-700">
                  Guide Price
                </span>
                <span className="text-lg font-semibold text-blue-600">
                  ${(guideData?.price * daysBetween * COMMISSION).toFixed(2)}
                </span>
              </div>
            )}
            {selectedPackage && (
              <div className="flex justify-between items-center py-2">
                <span className="text-lg font-semibold text-gray-700">
                  Package
                </span>
                <span className="text-lg font-semibold text-blue-600">
                  $
                  {(
                    guideData?.userPackageData?.find(
                      (p) => p.id === selectedPackage
                    )?.price * COMMISSION
                  ).toFixed(2)}
                </span>
              </div>
            )}
            {selectedVehicle && (
              <div className="flex justify-between items-center py-2">
                <span className="text-lg font-semibold text-gray-700">
                  Vehicle
                </span>
                <span className="text-lg font-semibold text-blue-600">
                  $
                  {(
                    guideData?.userVehicleData?.find(
                      (v) => v.id === selectedVehicle
                    )?.price *
                    daysBetween *
                    COMMISSION
                  ).toFixed(2)}
                </span>
              </div>
            )}
            <div className="flex justify-between items-center border-t border-gray-300 pt-3 mt-3">
              <span className="text-xl font-bold text-gray-800">
                Total Cost
              </span>
              <span className="text-xl font-bold text-green-600">
                ${totalCost.toFixed(2)}
              </span>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={totalCost == 0}
            >
              Submit Booking Request
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <div className="flex justify-end p-4 absolute">
      <Button
        type="default"
        shape="round"
        size="large"
        className="shadow-lg bookingreq-button"
        icon={
          <SendOutlined
            style={{
              fontSize: "1.5rem",
              transform: "rotate(-40deg)",
            }}
          />
        }
        onClick={showDrawer}
      >
        Send a Booking request
      </Button>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <p className="my-0 py-0">Send a Booking request</p>
            <Tag color="blue">Total $ {totalCost.toFixed(2)}</Tag>
          </div>
        }
        height={800}
        width={isMobile ? "100%" : 500}
        placement={isMobile ? "bottom" : "right"}
        closable={true}
        onClose={onClose}
        className="z-[999]"
        open={open}
        key={isMobile ? "bottom" : "right"}
      >
        {renderBookingCompo()}
      </Drawer>
    </div>
  );
};
