import React, { useState } from "react";
import {
  Upload,
  Col,
  Row,
  Modal,
  Image,
  Button,
  UploadFile,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { IMAGE_URL } from "../../../Constant";

export const HotelGallery = ({
  handleFormSubmitGalleryImage,
  initialValues,
}) => {
  const [imageList, setImageList] = useState<UploadFile[]>(
    initialValues.map((img: any, index: any) => ({
      uid: img.id || index.toString(),
      name: img.path,
      status: "done",
      url: `${IMAGE_URL}/upload/${img.path}`,
    }))
  );
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>("");

  const handlePreview = (file: any) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }: any) => {
    setImageList(fileList);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const imageUploadButton = (text: string) => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {text}</div>
    </div>
  );

  const onSubmit = () => {
    handleFormSubmitGalleryImage(imageList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    return isJpgOrPng;
  };

  const handleRemove = (file) => {
    const updatedList = imageList.filter((img) => img.uid !== file.uid);
    setImageList(updatedList);
  };

  return (
    <div className="hotel-gallery">
      <h2 className="mt-3">Hotel Gallery</h2>

      <div className="mt-4 text-end mb-5">
        <Button type="primary" onClick={onSubmit}>
          Save Gallery and Next
        </Button>
      </div>

      {/* Upload Image Section */}
      <Upload
        listType="picture-card"
        fileList={imageList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
        maxCount={9}
        accept="image/*"
        multiple={true}
      >
        {imageUploadButton("Gallery image")}
      </Upload>

      {/* Image Preview Modal */}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};
