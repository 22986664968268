import { Col, Row, Typography } from "antd";
import { HotelProfile, LoadingScreen } from "../../../components";
import { useAuthCheck } from "../../../Hook";
import React from "react";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

export const HotelDashBoard = () => {
  const { userid } = useParams();
  const { isAuthenticated, isLoading } = useAuthCheck(userid);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  if (isLoading)
    return <LoadingScreen message="Page is loading. Please wait a moment." />;
  if (!isAuthenticated) return <LoadingScreen message="Redirecting..." />;

  return (
    <>
      <div className="my-5 ms-5">
        <HotelProfile userid={userid} />
      </div>
      <div className="text-center">
        <Row justify="center" align="middle" className="h-screen">
          <Col span={24}>
            <Title level={3}>
              Page Under Development
            </Title>
            <Text type="secondary" style={{ fontSize: "16px" }}>
              We're working hard to get this page up and running. Stay tuned!
            </Text>
          </Col>
        </Row>
      </div>
    </>
  );
};
