import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_URL, PAGENOTFOUND } from "../../Constant";
import { toast } from "react-toastify";
import { GetHotelBioData } from "../../../src/Api";
import { HotelBookingReqCard, ReviewCategory } from "../../../src/components";
import { useGenerateStars } from "../../../src/Context";
import { Card, Col, Empty, Image, Row, Tabs } from "antd";
import { HotelDetails } from "./common/HotelDetails";
import TabPane from "antd/es/tabs/TabPane";
import { HotelGeneralFacilities } from "./common/HotelGeneralFacilities";

export const HotelSingleView = () => {
  const { userid } = useParams();
  const navigate = useNavigate();
  const generateStars = useGenerateStars();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userBioData, setUserBioData] = useState<any>([]);
  const [hotelFacilities, setHotelFacilities] = useState<any>([]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await GetHotelBioData(userid);

      if (data && data.isUserExists) {
        setUserBioData(data?.userBioData);
        setHotelFacilities(data?.hotelFacilities);
      } else {
        navigate(`../${PAGENOTFOUND}`);
      }
    } catch (error) {
      toast.error("Somthing went wrong. please try againg later");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userid) {
      fetchData();
    }
  }, [userid]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <Spin />
          <p className="my-0 py-0 ms-5">
            Data is loading. please wait a moment
          </p>
        </div>
      ) : (
        <>
          <HotelBookingReqCard hotelData={userBioData} />
          <div className="flex container flex-col items-start md:flex-row my-5 md:my-0">
            <div className="flex flex-col items-center justify-center">
              <h1 className="md:hidden font-extrabold text-4xl mt-10 md:mt-0 text-center uppercase">
                {userBioData?.name} <br />{" "}
                <small className="text-md">
                  {generateStars(userBioData?.rating)}({userBioData?.rating})
                </small>
              </h1>
              <Image
                src={`${IMAGE_URL}/upload/${userBioData?.profileImage}`}
                height={300}
                width={300}
                className="object-cover rounded-lg shadow-lg"
              />

              <HotelDetails hotelData={userBioData} />
            </div>

            <div className="container-fluid md:container">
              <div>
                <h1 className="hidden md:block font-extrabold text-4xl uppercase">
                  {userBioData?.name} <br />{" "}
                  <small className="text-md">
                    {generateStars(userBioData?.rating)}({userBioData?.rating})
                  </small>
                </h1>
                <p
                  className="mt-5 md:mt-0"
                  style={{
                    letterSpacing: "0.5px",
                    lineHeight: "1.6",
                  }}
                  dangerouslySetInnerHTML={{ __html: userBioData?.overview }}
                ></p>
              </div>

              <div className="flex flex-col md:flex-row justify-start my-5">
                <div className="container mt-10 md:mt-0 px-0 md:px-5">
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Details" key="1">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        <HotelGeneralFacilities
                          hotelFacilities={hotelFacilities}
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="Gallery" key="2">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        {/* <ImageGallery imagesArray={userGalleryData} /> */}
                      </div>
                    </TabPane>
                    <TabPane tab="Room(s)" key="3">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        {/* <PackageList packageData={userPackageData} /> */}
                      </div>
                    </TabPane>
                    <TabPane tab="Package" key="4">
                      {/* {userReviewsData?.lenght > 0 ? (
                        <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                          <Row gutter={[16, 16]}>
                            <>
                              {userReviewsData.map((data, index) => (
                                <Col span={24} md={12} lg={12} key={index}>
                                  <Card className="shadow-lg rounded-lg">
                                    <ReviewCard
                                      name={data.name}
                                      avatar={data.profileImage}
                                      review={data.comment}
                                      rate={data.averageRating}
                                    />
                                  </Card>
                                </Col>
                              ))}
                            </>
                          </Row>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-center items-center h-[80vh]">
                            <Empty />
                          </div>
                        </>
                      )} */}
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
