const env = process.env.REACT_APP_APP_ENV;
const commonConfig = {
  webName: "trainedguide.com",

  facebook: "https://web.facebook.com/profile.php?id=61560034121554",
  instergram: "https://www.instagram.com/beachsrilankatravel/",

  address: "No.125/ B, Hithgoda ,Wettewa, Galagedara , Sri lanka.",
  mobile: "+94717798841",
  email: "info@trainedguide.com",
  whatsapp: "+94717798841",
  devsSite: "https://taprodev.com/",

  noImage: "No_Image_Available.jpg",
  yttute: "https://www.youtube.com/embed/B9Utn4XouZ0?si=n0qiF_p8Dhpyw7jf",
  yttuteGuide: "https://www.youtube.com/embed/hyvmCL523bk?si=nwNZIbF8mzxaAXFX",

  // Payment settings
  firstPayment: 20,
  secondPayment: 80,
  commission: 1.2,
  currency: "USD",
};

const baseDomainProd = window.location.hostname.includes("www")
  ? "https://www.trainedguide.com"
  : "https://trainedguide.com";

const baseDomainQa = window.location.hostname.includes("www")
  ? "https://www.qa.trainedguide.com"
  : "https://qa.trainedguide.com";

const environments = {
  development: {
    webLink: "http://localhost:3000",
    server: "http://localhost/app/newtrainedguide/trained-guide/server",
    serverapi: "http://localhost/app/newtrainedguide/trained-guide/server/Api",
    imagepath: `http://localhost/app/newtrainedguide/trained-guide/image`,
  },
  qa: {
    webLink: `${baseDomainQa}`,
    server: `${baseDomainQa}/server`,
    serverapi: `${baseDomainQa}/server/Api`,
    imagepath: `${baseDomainQa}/image`,
  },
  production: {
    webLink: `${baseDomainProd}`,
    server: `${baseDomainProd}/server`,
    serverapi: `${baseDomainProd}/server/Api`,
    imagepath: `${baseDomainProd}/image`,
  },
};

// Merge common configuration with environment-specific configuration
const config = {
  ...commonConfig,
  ...environments[env],

  // Web routes
  registerUrl: "register",
  bookingUrl: "booking",
  homepageurl: "/",
  gallerypageurl: "gallery",
  searchGuidegeurl: "selecte-guide",
  searchHotelurl: "selecte-hotel",
  termspageurl: "terms-and-condition",
  abouturl: "about-us",
  contacturl: "contact-page",
  guidepublicprofile: "guide-public-profile",
  auth: "auth-page",
  logingsuccess: "loging-success",
  dashboard: "dashboard",
  inbox: "inbox",
  pagenotfound: "page-not-found",
  hotelpublicprofile: "hotel-public-profile",


  // Guide routes
  guidetermspageurl: "terms-and-condition",

  // Admin routes
  adminprofile: "profile",
  guidemanage: "guidemanage",
  manage:
    "https://app.kinde.com/auth/cx/_:nav&m:login&psid:dc9a242a19e24a26a1f7e6c4927e5d59",
  accounts: "https://sandbox.payhere.lk/merchant/payments",
};

export default config;
