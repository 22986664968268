import { getProfileStatusColor } from "../../../../src/lib";
import { AddGuideStatus, AdminGuideDataForTable } from "../../../../src/Api";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  InputRef,
  MenuProps,
  Space,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";
import { guideTypes, IAdminreviewStatus } from "../../../../src/Constant";
import { GuideProfile, LoadingScreen } from "../../../../src/components";
import { useParams } from "react-router-dom";
import { useAuthCheck } from "../../../Hook";

interface DataType {
  key: string;
  kd_id: string;
  name: string;
  type: string;
  Profile_status: string | null;
  contactNumber: string | null;
  action: any;
  guidetype: any;
}

type DataIndex = keyof DataType;

export const GuideManage = () => {
  const { userid } = useParams();
  const { isAuthenticated, isLoading } = useAuthCheck(userid);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState<DataIndex | "">("");
  const searchInput = useRef<InputRef>(null);
  const [isPofileDrawerVisible, setIsProfileDrawerVisible] =
    useState<boolean>(false);

  const fetchData = async () => {
    try {
      const data = await AdminGuideDataForTable();
      if (data?.isDataSet) {
        setTableData(data?.guideTableData);
      } else {
        toast.error("Something went wrong");
      }
    } catch {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "#",
      key: "index",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "kd_id",
      dataIndex: "kd_id",
      key: "kd_id",
      width: "10%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Profile Status",
      dataIndex: "Profile_status",
      key: "Profile_status",
      filters: [
        {
          text: IAdminreviewStatus.accepted,
          value: IAdminreviewStatus.accepted,
        },
        { text: IAdminreviewStatus.pending, value: IAdminreviewStatus.pending },
        {
          text: IAdminreviewStatus.rejected,
          value: IAdminreviewStatus.rejected,
        },
        { text: IAdminreviewStatus.blocked, value: IAdminreviewStatus.blocked },
      ],
      onFilter: (value, record) =>
        record.Profile_status?.toString().toLowerCase() ===
        value.toString().toLowerCase(),
      render: (status) => {
        const statusColor = getProfileStatusColor(status);
        return <Tag color={statusColor}>{status}</Tag>;
      },
      width: "20%",
    },
    {
      title: "Guide type",
      dataIndex: "guidetype",
      key: "guidetype",
      filters: guideTypes.map((guideType) => ({
        text: guideType.label,
        value: guideType.value,
      })),
      onFilter: (value, record) =>
        record.guidetype?.toString().toLowerCase() ===
        value.toString().toLowerCase(),
      render: (text) => text || "N/A",
      width: "20%",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const menu: MenuProps = {
          items: [
            {
              label: IAdminreviewStatus.accepted,
              key: "1",
              onClick: () =>
                handleAdminReviewAction(
                  IAdminreviewStatus.accepted,
                  record?.kd_id,
                  "The profile has been accepted based on the review criteria."
                ),
            },
            {
              label: IAdminreviewStatus.rejected,
              key: "2",
              onClick: () =>
                handleAdminReviewAction(
                  IAdminreviewStatus.rejected,
                  record?.kd_id,
                  " The profile has been rejected due to not meeting the required criteria."
                ),
            },
            {
              label: IAdminreviewStatus.blocked,
              key: "3",
              onClick: () =>
                handleAdminReviewAction(
                  IAdminreviewStatus.blocked,
                  record?.kd_id,
                  "The profile has been blocked for further review or due to specific reasons."
                ),
            },
          ],
        };
        return (
          <Space size="middle">
            <Dropdown menu={menu}>
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
            <GuideProfile userid={record.kd_id} />
          </Space>
        );
      },
      width: "30%",
    },
  ];

  const handleAdminReviewAction = async (status, userid, comment) => {
    const data = await AddGuideStatus({ status, userid, comment });
    if (data?.isDataAdded) {
      toast.success(data?.msg);
      fetchData();
    }
  };

  const closeProfileReviewDrawer = () => {
    setIsProfileDrawerVisible(false);
  };

  if (isLoading)
    return <LoadingScreen message="Page is loading. Please wait a moment." />;
  if (!isAuthenticated) return <LoadingScreen message="Redirecting..." />;

  return (
    <>
      <div className="container shadow-md p-5 h-[100vh] overflow-y-scroll">
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>

      <Drawer
        title="Profile review"
        placement="right"
        closable={true}
        onClose={closeProfileReviewDrawer}
        visible={isPofileDrawerVisible}
        width={500}
      ></Drawer>
    </>
  );
};
