import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  Checkbox,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GetHotelFacilityData } from "../../../Api";
import { HotelProfileSection, IMAGE_URL } from "../../../Constant";
import { toast } from "react-toastify";

const { TextArea } = Input;
const { Option } = Select;

type RoomType = {
  id: string;
  roomName: string;
  roomType: string;
  roomSize: string;
  maxOccupancy: string;
  pricePerDay: string;
  pricePerNight: string;
  roomTerms: string;
  roomfacilities: string;
  description: string;
  image: string;
};

type Props = {
  handleFormSubmiHotelRoom: (values: any) => void;
  initialValues: RoomType[];
  setActiveSection: any;
};

export const HotelRooms: React.FC<Props> = ({
  handleFormSubmiHotelRoom,
  initialValues,
  setActiveSection,
}) => {
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState<any[]>([]);
  const [roomFacility, setRoomFacility] = useState<any[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<RoomType | null>(null);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    // Fetch Room Facilities Data on Mount
    const fetchFacilityData = async () => {
      try {
        const data = await GetHotelFacilityData();
        setRoomFacility(data?.facilityTableData?.room_facilities || []);
      } catch (error) {
        toast.error("Failed to load room facilities!");
      }
    };

    fetchFacilityData();
  }, []);

  useEffect(() => {
    if (selectedRoom?.image) {
      try {
        const parsedImages = JSON.parse(selectedRoom.image);
        setImageList(
          parsedImages.map((img: string, index: number) => ({
            uid: index,
            url: `${IMAGE_URL}/upload/${img}`,
          }))
        );
      } catch (error) {
        toast.error("Invalid image format for selected room.");
      }
    }
  }, [selectedRoom]);

  const onFinish = (values: any) => {
    if (selectedRoom) {
      const data = {
        ...values,
        roomId: selectedRoom.id,
      };

      handleFormSubmiHotelRoom(data);
    } else {
      handleFormSubmiHotelRoom(values);
    }
    form.resetFields();
    setImageList([])
  };

  const handleRoomClick = (index: number) => {
    const room = initialValues[index];
    setSelectedRoom(room);
    form.setFieldsValue({
      roomName: room.roomName,
      roomType: room.roomType,
      roomSize: parseInt(room.roomSize, 10),
      maxOccupancy: parseInt(room.maxOccupancy, 10),
      pricePerDay: parseFloat(room.pricePerDay),
      pricePerNight: parseFloat(room.pricePerNight),
      roomTerms: room.roomTerms,
      roomfacilities: room.roomfacilities
        ? JSON.parse(room.roomfacilities)
        : [],
      description: room.description,
    });
  };

  const beforeUpload = (file: any) => {
    const isValid = file.type === "image/jpeg" || file.type === "image/png";
    if (!isValid) toast.error("You can only upload JPG/PNG files!");
    return isValid;
  };

  const handleUpload = ({ fileList }: { fileList: any[] }) => {
    setImageList(fileList);
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const getBase64 = (file: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleRemove = (file) => {
    const updatedList = imageList.filter((img) => img.uid !== file.uid);
    setImageList(updatedList);
  };

  return (
    <div className="hotel-rooms">
      <h2 className="mt-3">Hotel Rooms</h2>

      {/* Room Form */}
      <Form form={form} onFinish={onFinish} layout="vertical">
        {/* Room Image Upload */}
        <Form.Item
          label="Room Image"
          name="roomImage"
          rules={[{ required: true, message: "Please upload a room image!" }]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            fileList={imageList}
            onChange={handleUpload}
            beforeUpload={beforeUpload}
            onPreview={handlePreview}
            onRemove={handleRemove}
            multiple
          >
            {imageList.length >= 1 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        {/* Room Details */}
        <Form.Item
          label="Room type"
          name="roomType"
          rules={[{ required: true, message: "Please input the room type!" }]}
        >
          <Input placeholder="Enter room type" />
        </Form.Item>

        {/* Max Occupancy */}
        <Form.Item
          label="Max Occupancy"
          name="maxOccupancy"
          rules={[
            {
              required: true,
              message: "Please specify the maximum occupancy!",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Enter max occupancy"
          />
        </Form.Item>

        <Form.Item
          className="mt-5"
          label="Room Facilities"
          name="roomfacilities"
        >
          <Checkbox.Group className="flex custome-checkbox-btn-hotel-profile flex-wrap gap-[15px]">
            {roomFacility?.map((option: any) => (
              <Checkbox key={option?.id} value={option?.id}>
                {option?.facility?.replace(/_/g, " ").toUpperCase()}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        {/* Room Description */}
        <Form.Item
          label="Room Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please provide a description for the room!",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Enter room description" />
        </Form.Item>

        {/* Room Terms & Conditions */}
        <Form.Item label="Room Terms & Conditions" name="roomTerms">
          <TextArea rows={4} placeholder="Enter room terms & conditions" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit Room Details
          </Button>
        </Form.Item>
      </Form>

      <Button
        onClick={() => {
          setActiveSection(HotelProfileSection.package);
        }}
      >
        Next steps
      </Button>

      {/* Room List */}
      <Row gutter={16} style={{ marginTop: 20 }}>
        {initialValues.map((room, index) => {
          const firstImage = JSON.parse(room.image)[0];
          return (
            <Col span={8} key={room.id}>
              <div
                className="room-card"
                onClick={() => handleRoomClick(index)}
                style={{ border: "1px solid #ddd", padding: "20px" }}
              >
                <img
                  src={`${IMAGE_URL}/upload/${firstImage}`}
                  alt={room.roomName}
                  style={{ width: "100%" }}
                />
                <h3>{room.roomName}</h3>
              </div>
            </Col>
          );
        })}
      </Row>

      {/* Image Preview Modal */}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};
