import React from "react";
import { List, Card, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { COMMISSION } from "../../../Constant";

const { Title } = Typography;

export const PackageList = ({ packageData }) => {
  return (
   <div className="">
     <List
      grid={{ gutter: 0, column: 1 }}
      dataSource={packageData}
      className="mx-auto px-0 w-[90vw] md:w-full"
      renderItem={(item: any) => (
        <List.Item className="mx-0 px-0">
          <Card
            title={<Title level={3}>{item?.package_title}</Title>}
            extra={
              <Title level={4}>
                ${(item?.package_price * COMMISSION).toFixed(2)}
              </Title>
            }
            style={{ width: "100%" }}
            className="shadow-xl py-3"
          >
            <div className="ms-5">
              {item.package_daynote.map((day, index) => (
                <div key={index} style={{ marginBottom: "8px" }}>
                  <EnvironmentOutlined
                    style={{ marginRight: "8px", color: "#1890ff" }}
                  />
                  Day {index + 1} : {day.dayNote}
                </div>
              ))}
            </div>
            <p
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: item?.package_additionalNote }}
            ></p>
          </Card>
        </List.Item>
      )}
    />
   </div>
  );
};
