import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Image, Row, Spin, Tabs } from "antd";
import {
  IMAGE_URL,
  PAGENOTFOUND,
  IUserBioDataPublicProfile,
} from "../../Constant";
import {
  GuideBookingReqCard,
  ReviewCard,
  ReviewCategory,
} from "../../components";
import GuideDetails from "./common/GuideDetails";
import { useGenerateStars } from "../../Context";
import { ImageGallery, PackageList, VehicleForm } from "./common";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate, useParams } from "react-router-dom";
import { GetGuideBioData } from "../../Api";
import { toast } from "react-toastify";

export const GuideSingleView = () => {
  const { userid } = useParams();
  const navigate = useNavigate();
  const generateStars = useGenerateStars();
  const [userBioData, setUserBioData] = useState<IUserBioDataPublicProfile>();
  const [userVehicleData, setUserVehicleData] = useState<any>([]);
  const [userGalleryData, setUserGalleryData] = useState<any>([]);
  const [userPackageData, setUserPackageData] = useState<any>([]);
  const [userReviewsData, setUserReviewsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userBookingReqData, setUserBookingReqData] = useState<any>([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await GetGuideBioData(userid);
      if (data && data.isUserExists) {
        setUserBioData(data?.userBioData);
        setUserVehicleData(data?.userVehicleData);
        setUserGalleryData(data?.userGalleryData);
        setUserPackageData(data?.userPackageData);
        setUserReviewsData(data?.userReviewsData);
        setUserBookingReqData(data?.userBookingReqData);
      } else {
        navigate(`../${PAGENOTFOUND}`);
      }
    } catch (error) {
      toast.error("Somthing went wrong. please try againg later");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userid) {
      fetchData();
    }
  }, [userid]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <Spin />
          <p className="my-0 py-0 ms-5">
            Data is loading. please wait a moment
          </p>
        </div>
      ) : (
        <>
          <GuideBookingReqCard guideData={userBookingReqData} />
          <div className="flex container flex-col items-start md:flex-row my-5 md:my-0">
            <div className="flex flex-col items-center justify-center">
              <h1 className="md:hidden font-extrabold text-4xl mt-10 md:mt-0 text-center uppercase">
                {userBioData?.name} <br />{" "}
                <small className="text-md">
                  {generateStars(userBioData?.rate)}({userBioData?.rate})
                </small>
              </h1>
              <Image
                src={`${IMAGE_URL}/upload/${userBioData?.profileImage}`}
                height={300}
                width={300}
                className="object-cover rounded-lg shadow-lg"
              />
              <div className="my-5 w-[70vw] md:w-full">
                <ReviewCategory data={userBioData?.reviewData} />
              </div>
            </div>

            <div className="container-fluid md:container">
              <div>
                <h1 className="hidden md:block font-extrabold text-4xl uppercase">
                  {userBioData?.name} <br />{" "}
                  <small className="text-md">
                    {generateStars(userBioData?.rate)}({userBioData?.rate})
                  </small>
                </h1>
                <p
                  className="mt-5 md:mt-0"
                  style={{
                    letterSpacing: "0.5px",
                    lineHeight: "1.6",
                  }}
                  dangerouslySetInnerHTML={{ __html: userBioData?.aboutYou }}
                ></p>
              </div>

              <div className="flex flex-col md:flex-row justify-start my-5">
                <div className="mx-auto w-full md:w-[50%]">
                  <GuideDetails guideData={userBioData} />
                </div>

                <div className="container mt-10 md:mt-0 px-0 md:px-5">
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Vehicle" key="1">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        <VehicleForm vehicles={userVehicleData} />
                      </div>
                    </TabPane>
                    <TabPane tab="Gallery" key="2">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        <ImageGallery imagesArray={userGalleryData} />
                      </div>
                    </TabPane>
                    <TabPane tab="Packages" key="3">
                      <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                        <PackageList packageData={userPackageData} />
                      </div>
                    </TabPane>
                    <TabPane tab="Review" key="4">
                      {userReviewsData?.lenght > 0 ? (
                        <div className="h-[60vh] overflow-y-scroll overflow-x-hidden">
                          <Row gutter={[16, 16]}>
                            <>
                              {userReviewsData.map((data, index) => (
                                <Col span={24} md={12} lg={12} key={index}>
                                  <Card className="shadow-lg rounded-lg">
                                    <ReviewCard
                                      name={data.name}
                                      avatar={data.profileImage}
                                      review={data.comment}
                                      rate={data.averageRating}
                                    />
                                  </Card>
                                </Col>
                              ))}
                            </>
                          </Row>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-center items-center h-[80vh]">
                            <Empty />
                          </div>
                        </>
                      )}
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
