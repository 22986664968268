import React, { useState } from "react";
import { Form, List, Modal, Spin } from "antd";
import { COMMISSION, IMAGE_URL } from "../../../Constant";
import { EyeOutlined } from "@ant-design/icons";

export const VehicleForm = ({ vehicles }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  return (
    <div className="shadow-md mx-auto h-[80vh]">
      <Form layout="vertical" initialValues={{ ...vehicles }}>
        <List
          dataSource={vehicles}
          renderItem={(vehicle: any, key: any) => (
            <List.Item>
              <div className="flex flex-col items-start px-2">
                <div>
                  <div className="col-span-1">
                    <div>
                      <span className="font-bold">Name:</span>{" "}
                      {vehicle.vehicle_name}
                    </div>
                    <div>
                      <span className="font-bold">Price per day:</span> $
                      {(vehicle.vehicle_price * COMMISSION).toFixed(2)}
                    </div>
                    <div>
                      <span className="font-bold">Note:</span>{" "}
                      {vehicle.vehicle_note}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-start">
                  {vehicle.images &&
                    vehicle.images.map((img: any, key: any) => (
                      <div
                        key={key}
                        className="relative overflow-hidden cursor-pointer group"
                        onClick={() => showModal(img)}
                      >
                        <img
                          src={`${IMAGE_URL}/upload/${img}`}
                          className="w-full h-48 object-cover rounded-lg transition-transform transform hover:scale-105"
                        />

                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <EyeOutlined className="text-white text-xs" />
                          <span className="text-white text-xs mt-1">
                            Preview
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </List.Item>
          )}
        />
      </Form>

      {currentImage && (
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          width={"80vh"}
          height={"80vh"}
          centered
        >
          <img
            src={`${IMAGE_URL}/upload/${currentImage}`}
            alt={currentImage?.alt}
            className="w-full h-auto p-0 m-0"
          />
        </Modal>
      )}

      
    </div>
  );
};
