import * as React from "react";
import { cn, scrollToTop } from "../../lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu";
import { Link } from "react-router-dom";
import { PlaneLandingIcon } from "lucide-react";
import {
  ABOUTAGEURL,
  CONTACTPAGEURL,
  GALLERYPAGEURL,
  HOMEPAGEURL,
  SEARCHGUIDE,
  SEARCHHOTEL,
  TERMSPAGEURL,
  YT_TUTORIAL,
} from "../../Constant";
import { TopNav } from "./Top-nav";

const components: { title: string; href: string; description: string }[] = [
  {
    title: "Search Guide",
    href: `${SEARCHGUIDE}/null/null/null/htl`,
    description: "Find and book the perfect tour guide for your adventure.",
  },
  {
    title: "Search Hotel",
    href: `${SEARCHHOTEL}/null/null/null/htl`,
    description:
      "Find the perfect hotel for your stay and explore our exclusive options.",
  },
  {
    title: "Gallery",
    href: GALLERYPAGEURL,
    description: "Browse stunning photos from our past tours and experiences.",
  },
  {
    title: "About Us",
    href: ABOUTAGEURL,
    description: "Learn more about our team and the services we offer.",
  },
  {
    title: "Contact Us",
    href: CONTACTPAGEURL,
    description: "Get in touch with us for any inquiries or support.",
  },
  {
    title: "Terms & Conditions",
    href: TERMSPAGEURL,
    description: "Read our terms and conditions for using our services.",
  },
  {
    title: "How to Use",
    href: YT_TUTORIAL,
    description:
      "Watch our tutorial to understand how to book and manage your tours.",
  },
];

export function NavigationBar() {
  return (
    <div className="text-center flex flex-col justify-center items-center sticky top-0 z-[998]">
      <TopNav />
      <div className="pt-3 pb-1 bg-white w-full flex justify-center">
        <NavigationMenu>
          <NavigationMenuList>
            <div className="flex flex-col md:flex-row justify-center align-middle items-center mx-auto">
              <div>
                <NavigationMenuItem>
                  <Link to={HOMEPAGEURL}>
                    <NavigationMenuLink
                      className={navigationMenuTriggerStyle()}
                      onClick={scrollToTop}
                    >
                      Home
                    </NavigationMenuLink>
                  </Link>
                  <Link to={`${SEARCHGUIDE}/null/null/null/htl`}>
                    <NavigationMenuLink
                      className={navigationMenuTriggerStyle()}
                      onClick={scrollToTop}
                    >
                      Search Guide
                    </NavigationMenuLink>
                  </Link>
                  <Link to={`${SEARCHHOTEL}/null/null/null/htl`}>
                    <NavigationMenuLink
                      className={navigationMenuTriggerStyle()}
                      onClick={scrollToTop}
                    >
                      Search Hotel
                    </NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
              </div>
              <div className="flex flex-row">
                <NavigationMenuItem>
                  <NavigationMenuTrigger>Getting started</NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                      <li className="row-span-3">
                        <NavigationMenuLink asChild>
                          <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                            <PlaneLandingIcon />
                            <div className="mb-2 mt-4 text-lg font-medium">
                              Trained Guide.com
                            </div>
                            <p className="text-sm leading-tight text-muted-foreground">
                              Discover amazing places with our expert tour
                              guides! Tailored experiences, from historic sites
                              to local cuisine and adventure, await you. Join us
                              for an inspiring journey!
                            </p>
                          </div>
                        </NavigationMenuLink>
                      </li>

                      <ListItem
                        title="Search Your Guide"
                        to={`${SEARCHGUIDE}/null/null/null/htl`}
                        onClick={scrollToTop}
                      >
                        Find the perfect guide for your adventure and explore
                        our offerings.
                      </ListItem>
                      <ListItem
                        title="Search Your Hotel"
                        to={`${SEARCHHOTEL}/null/null/null/htl`}
                        onClick={scrollToTop}
                      >
                        Find the perfect hotel for your stay and explore our
                        exclusive options.
                      </ListItem>
                      <ListItem
                        to={GALLERYPAGEURL}
                        title="Our Gallery"
                        onClick={scrollToTop}
                      >
                        Explore stunning images and highlights from our tours.
                      </ListItem>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>Nav</NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid gap-3 p-4 sm:w-[300px] md:w-[400px] md:grid-cols-2 lg:w-[600px]">
                      {components.map((component) => (
                        <ListItem
                          key={component.title}
                          title={component.title}
                          to={component.href}
                          onClick={scrollToTop}
                        >
                          {component.description}
                        </ListItem>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </div>
            </div>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </div>
  );
}

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof Link> & { title: string }
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
