import { toast } from "react-toastify";
import axios from "axios";
import { HotelProfileSection, SERVER_API } from "../../../src/Constant";

export const AddHotelProfileData = async (
  formData: any,
  type: any,
  userId: any
) => {
  try {
    const formDataSet = new FormData();

    //data set
    formDataSet.append("dataType", type);
    formDataSet.append("userId", userId);

    // Append other form data
    formDataSet.append(
      "inputs",
      JSON.stringify({
        ...formData,
      })
    );

    //GENARAL DATA SET
    if (type == HotelProfileSection.general) {
      // Append the profile image if it exists
      if (formData?.profileImage?.file) {
        formDataSet.append(
          "profileImageArray[]",
          formData.profileImage.file.originFileObj
        );
      }

      // Append the licenceImage image if it exists
      if (formData?.licenceImage?.file) {
        formDataSet.append(
          "licenceImageArray[]",
          formData.licenceImage.file.originFileObj
        );
      }
    }

    //GALLERY DATA SET
    if (type == HotelProfileSection.gallery) {
      if (formData && Array.isArray(formData)) {
        formData.forEach((image) => {
          if (image.originFileObj) {
            formDataSet.append("hotelGalleryImageArray[]", image.originFileObj);
          }
        });
      }
    }
    
    //ROOM DATA SET
    if (type == HotelProfileSection.room) {
      
      if (formData && formData?.roomImage) {
        formData?.roomImage?.fileList?.forEach((image) => {
          if (image.originFileObj) {
            formDataSet.append("hotelRoomImageArray[]", image.originFileObj);
          }          
        });
      }
    }

    const response = await axios.post(
      `${SERVER_API}/Hotel/Add-hotel-profile-data.php`,
      formDataSet,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    toast.error("Something went wrong");
  }
};
