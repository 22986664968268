import React, { useRef, useState } from "react";
import { Button, Form, Input, Table } from "antd";
import ReactQuill from "react-quill";

export const HotelPackage: React.FC<any> = ({
  packageData,
  packageColumnData,
  packageTerms,
  handleFormSubmiHotelPackage,
}: any) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(packageData);
  const editingRow = useRef<string | null>(null);
  const editingColumn = useRef<string | null>(null);
  const [editorValue, setEditorValue] = useState<string>(
    JSON.parse(packageTerms) ?? ""
  );

  const EditableCell = ({
    record,
    dataIndex,
    editing,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={[record.key, dataIndex]}
            style={{ margin: 0, width: "75px" }}
            key={dataIndex}
          >
            <Input
              style={{ textAlign: "center" }}
              autoFocus={
                editingRow.current === record.key &&
                editingColumn.current === dataIndex
              }
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  // Table columns
  const columns: any = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      fixed: "left",
      render: (value, row, index) => {
        const obj: { children: React.ReactNode; props: { rowSpan?: number } } =
          {
            children: value,
            props: {},
          };
        if (index % 2 === 0) {
          obj.props.rowSpan = 2;
        } else {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: "left",
    },
    ...packageColumnData,
    {
      title: "3rd Person",
      children: [
        {
          title: "Adult",
          dataIndex: "thirdPersonAdult",
          key: "thirdPersonAdult",
          editable: true,
        },
        {
          title: "Child",
          dataIndex: "thirdPersonChild",
          key: "thirdPersonChild",
          editable: true,
        },
      ],
    },
  ];

  // Making columns editable
  const editableColumns = columns.map((col) => {
    if (col.children) {
      return {
        ...col,
        children: col.children.map((child) => ({
          ...child,
          onCell: (record) => ({
            record,
            dataIndex: child.dataIndex,
            editing: child.editable || false,
          }),
        })),
      };
    }

    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: col.editable || false, // Fix: Apply only to editable columns
      }),
    };
  });

  const handleValuesChange = (changedValues, allValues) => {
    const changedKey = Object.keys(changedValues)[0];
    const updatedValues = changedValues[changedKey];

    if (!changedKey || !updatedValues) return;

    const activeElement = document.activeElement;
    editingColumn.current = Object.keys(updatedValues)[0];

    setDataSource((prevData) =>
      prevData.map((item) =>
        item.key === changedKey ? { ...item, ...updatedValues } : item
      )
    );

    editingRow.current = changedKey;

    form.setFieldsValue({
      [changedKey]: updatedValues,
    });

    setTimeout(() => {
      if (
        activeElement &&
        (activeElement as HTMLInputElement).tagName === "INPUT"
      ) {
        (activeElement as HTMLInputElement).focus();
      }
    }, 0);
  };

  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };

  const hadleData = () => {
    const data = {
      packageData: dataSource,
      terms: editorValue,
    };
    handleFormSubmiHotelPackage(data);
  };

  return (
    <div className="p-4">
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        initialValues={Object.fromEntries(
          dataSource.map((item) => [item.key, item])
        )}
      >
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={editableColumns}
          bordered
          pagination={false}
          scroll={{ x: "max-content" }}
          rowClassName={(record, index) =>
            Math.floor(index / 2) % 2 === 0
              ? "bg-gray-200 hover:bg-gray-200"
              : "bg-white hover:bg-white"
          }
        />
      </Form>
      <ReactQuill
        className="mt-10 pb-5"
        value={editorValue}
        onChange={handleEditorChange}
        placeholder="Add description"
      />

      <Button onClick={hadleData}>Submit</Button>
    </div>
  );
};
