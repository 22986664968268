import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetAllHotelData = async (
  limit: any,
  page: any,
  filterData: any
) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Hotel/get-all-hotel-data.php?limit=${limit}&page=${page}&filterData=${JSON.stringify(
        filterData
      )}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};