import React from "react";

export const HotelGeneralFacilities = ({ hotelFacilities }:any) => {
  return (
    <div className="p-6 rounded-lg shadow-md">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {hotelFacilities && hotelFacilities?.generalFacilities?.map((facility, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-white p-4 rounded-lg shadow hover:shadow-lg transition"
          >
            <img
              src={facility.icon}
              alt={facility.facilities}
              className="w-12 h-12 mb-2"
            />
            <span className="text-gray-700 text-sm font-medium">
              {facility.facilities}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
