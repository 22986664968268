import React from "react";
import { Button } from "antd";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IMAGE_URL, UserRoles, UserRolesEnum } from "../../../src/Constant";
import { toast } from "react-toastify";
import { addUserRoleToSession } from "../../Hook";

export const AuthPage = () => {
  const { login, register } = useKindeAuth();
  
  const handleRegister = async (role: UserRoles) => {
    try {
      addUserRoleToSession(role);
      const user = await register();
    } catch (error) {
      toast.error("Error during registration! Please Try againg later");
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-[80vh]">
      <div className="md:w-1/2 w-full flex flex-col justify-center items-center bg-gray-100 p-8">
        <h1 className="text-3xl mb-4 font-bold">Join trainedGuide.com</h1>
        <p className="mb-6 text-md text-center">
          Discover amazing experiences and share your adventures with our
          vibrant community. Whether you want to guide others or find your next
          journey, sign up today and start exploring new opportunities!
        </p>
        <div className="flex space-x-4">
          <Button
            type="primary"
            size="large"
            onClick={() => handleRegister(UserRolesEnum.guide)}
          >
            Register as Guide
          </Button>
          <Button
            type="default"
            size="large"
            onClick={() => handleRegister(UserRolesEnum.guest)}
          >
            Register as Guest
          </Button>
          <Button
            type="default"
            size="large"
            onClick={() => handleRegister(UserRolesEnum.hotel)}
          >
            Register Your Hotel
          </Button>
        </div>

        <div className="flex flex-col mt-[10vh]">
          <p>Already have an account ?</p>
          <Button
            type="default"
            size="large"
            onClick={async () => {
              addUserRoleToSession(UserRolesEnum.user);
              await login();
            }}
          >
            Sign In
          </Button>
        </div>
      </div>
      <div className="md:w-1/2 w-full text-center flex items-center justify-center">
        <img src={`${IMAGE_URL}/welcome_image.png`} alt="Sign in image" />
      </div>
    </div>
  );
};
