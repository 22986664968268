import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { HOMEPAGEURL, UserRoles, UserRolesEnum } from "../Constant";

export const addUserRoleToSession = (role: UserRoles) => {
  sessionStorage.setItem("user_role", role);
};

export const getUserRoleToSession = () => {
  sessionStorage.getItem("user_role");
};

export const useAuthCheck = (requiredUserId) => {
  const { user, isLoading } = useKindeAuth();
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("user_role");

  useEffect(() => {
    if (isLoading) return;

    if (!user) {
      navigate(HOMEPAGEURL);
      return;
    }

    if (requiredUserId && user.id !== requiredUserId) {
      navigate(HOMEPAGEURL);
      return;
    }

    if (
      userRole !== UserRolesEnum.guest &&
      userRole !== UserRolesEnum.guide &&
      userRole !== UserRolesEnum.admin &&
      userRole !== UserRolesEnum.hotel
    ) {
      navigate(HOMEPAGEURL);
    }
  }, [user, isLoading, requiredUserId, userRole, navigate]);

  return {
    isAuthenticated: !!user,
    user,
    userRole,
    isLoading,
  };
};
