import React, { useState } from "react";
import { Empty, Modal, Spin } from "antd";
import { IMAGE_URL } from "../../../Constant";
import { EyeOutlined } from "@ant-design/icons";

export const ImageGallery = ({ imagesArray }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState<any>(
    Array(imagesArray.length).fill(true)
  );
  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  const handleImageLoad = (index: any) => {
    const newLoadingStatus = [...loadingStatus];
    newLoadingStatus[index] = false;
    setLoadingStatus(newLoadingStatus);
  };

  return (
    <div className="shadow-lg">
      <div>
        {imagesArray && imagesArray?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 h-auto">
            {imagesArray?.map((image, index) => (
              <div
                key={image?.id}
                className="relative overflow-hidden cursor-pointer group"
                onClick={() => showModal(image)}
              >
                <img
                  src={`${IMAGE_URL}/upload/${image?.url}`}
                  alt={image?.alt}
                  onLoad={() => handleImageLoad(index)}
                  className="w-full h-48 object-cover rounded-lg transition-transform transform hover:scale-105"
                />

                {loadingStatus[index] && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                    <Spin size="small" />
                  </div>
                )}

                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <EyeOutlined className="text-white text-xs" />
                  <span className="text-white text-xs mt-1">Preview</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center h-[80vh]">
              <Empty />
            </div>
          </>
        )}
      </div>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width={"80vh"}
        height={"80vh"}
        centered
      >
        <img
          src={`${IMAGE_URL}/upload/${currentImage?.url}`}
          alt={currentImage?.url}
          className="w-full h-auto p-0 m-0"
        />
      </Modal>
    </div>
  );
};
