import React, { useEffect, useState } from "react";
import { Form, Input, Tag, Space, Button } from "antd";

interface UniqueSellingPointsFormProps {
  usps: string[] | string | undefined;
  setUSPs: (usps: string[]) => void;
}

export const UniqueSellingPointsForm: React.FC<
  UniqueSellingPointsFormProps
> = ({ usps = [], setUSPs }) => {
  const [inputValue, setInputValue] = useState("");
  const [formattedUSPs, setFormattedUSPs] = useState<string[]>([]);

  // Convert `usps` to an array if it's a string
  useEffect(() => {
    if (typeof usps === "string") {
      setFormattedUSPs(usps.split(",").map((usp) => usp.trim()));
    } else if (Array.isArray(usps)) {
      setFormattedUSPs(usps);
    } else {
      setFormattedUSPs([]);
    }
  }, [usps]);

  // Add a USP
  const handleAddUSP = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !formattedUSPs.includes(trimmedValue)) {
      const updatedUSPs = [...formattedUSPs, trimmedValue];
      setFormattedUSPs(updatedUSPs);
      setUSPs(updatedUSPs);
      setInputValue("");
    }
  };

  // Remove a USP
  const handleRemoveUSP = (removedUSP: string) => {
    const updatedUSPs = formattedUSPs.filter((usp) => usp !== removedUSP);
    setFormattedUSPs(updatedUSPs);
    setUSPs(updatedUSPs);
  };

  return (
    <>
      <Form.Item
        label="Unique Selling Points (USPs)"
        name="usps"
        rules={[
          {
            required: true,
            message: "Please highlight the hotel's unique features",
          },
        ]}
      >
        <div
          className="flex rowjustify-center items-center"
          style={{ gap: "8px" }}
        >
          <Input
            placeholder="Type a USP"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button type="primary" size="middle" onClick={handleAddUSP}>
            Add USP
          </Button>
        </div>
      </Form.Item>
      {formattedUSPs.length > 0 && (
        <Form.Item label="Added USPs">
          <Space wrap>
            {formattedUSPs.map((usp, index) => (
              <Tag
                key={`${usp}-${index}`}
                color="default"
                closable
                onClose={() => handleRemoveUSP(usp)}
              >
                {usp}
              </Tag>
            ))}
          </Space>
        </Form.Item>
      )}
    </>
  );
};
