import { Button, Drawer, Form, Input, Spin } from "antd";
import { HotelProfileSection, UserRolesEnum } from "../../Constant";
import React, { useEffect, useState } from "react";
import { ProfileIcon } from "../Profile-icon/Profile-icon";
import { WhatsApp } from "../Whatsapp/WhatsApp";
import { GuideCalender } from "../Guide-calender/Guide-calender";
import { LoadingOutlined } from "@ant-design/icons";
import { GeneralHotelProfileData } from "./Common/HotelGenaralData";
import { HotelGallery } from "./Common/Hotel-gallery";
import { HotelRooms } from "./Common/HotelRooms";
import { AddHotelProfileData, GetHotelDataForProfile } from "../../Api";
import { toast } from "react-toastify";
import { HotelPackage } from "./Common/HotelPackage";

const { TextArea } = Input;

export const HotelProfile = ({ userid }) => {
  const user_role = sessionStorage.getItem("user_role");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [usps, setUSPs] = useState([]);

  const [activeSection, setActiveSection] = useState(
    HotelProfileSection.general
  );
  const [profileData, setProfileData] = useState<any>(null);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const fetchProfileData = async () => {
    try {
      const data = await GetHotelDataForProfile(userid);
      if (data?.isDataSet) {
        setProfileData(data?.hotelData);
      }
    } catch (error) {
      toast.error("Somthing went wrong.P_lease try againg later");
    }
  };

  const handleGeneralDataSubmit = async (value) => {
    const profileData = {
      ...value,
      usps: usps,
    };

    const data = await AddHotelProfileData(
      profileData,
      HotelProfileSection.general,
      userid
    );
    if (data?.isDataSet && data?.isDataAdded) {
      toast.success(data?.msg);
      setActiveSection(HotelProfileSection.gallery);
    }
  };

  const handleFormSubmitGalleryImage = async (value) => {
    const data = await AddHotelProfileData(
      value,
      HotelProfileSection.gallery,
      userid
    );
    if (data?.isDataSet && data?.isDataAdded) {
      toast.success(data?.msg);
      setActiveSection(HotelProfileSection.room);
    }
  };

  const handleFormSubmiHotelRoom = async (value) => {
    const data = await AddHotelProfileData(
      value,
      HotelProfileSection.room,
      userid
    );

    if (data?.isDataSet && data?.isDataAdded) {
      toast.success(data?.msg);
      setActiveSection(HotelProfileSection.room);
      fetchProfileData();
    }
  };

  const handleFormSubmiHotelPackage = async (value) => {
    const data = await AddHotelProfileData(
      value,
      HotelProfileSection.package,
      userid
    );

    if (data?.isDataSet && data?.isDataAdded) {
      toast.success(data?.msg);
      setActiveSection(HotelProfileSection.general);
      fetchProfileData();
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <>
      <>
        {user_role === UserRolesEnum.admin ? (
          <Button
            className="bg-blue-500 text-white"
            onClick={() => {
              showDrawer();
            }}
          >
            Check Profile
          </Button>
        ) : (
          <div className="flex justify-stat md:justify-end shadow-lg">
            <ProfileIcon showDrawer={showDrawer} />
            <WhatsApp />
            <GuideCalender />
          </div>
        )}
      </>

      {/* Drawer */}
      <Drawer
        title={
          <div className="hover:cursor-pointer flex justify-between items-center">
            <p className="mb-0">Profile </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        width={activeSection == HotelProfileSection.package ? 900 : 600}
      >
        <>
          {/* Bio Data add */}
          {isLoading ? (
            <>
              <div className="flex justify-center flex-col gap-y-10 items-center h-full">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
                <p>Profile data is loading. Please wait a moment.</p>
              </div>
            </>
          ) : (
            <>
              {activeSection === HotelProfileSection.general && (
                <GeneralHotelProfileData
                  handleFormSubmitGenaralData={handleGeneralDataSubmit}
                  initialValues={profileData?.generalData}
                  usps={usps}
                  setUSPs={setUSPs}
                />
              )}

              {activeSection === HotelProfileSection.gallery && (
                <HotelGallery
                  handleFormSubmitGalleryImage={handleFormSubmitGalleryImage}
                  initialValues={profileData?.gallery}
                />
              )}

              {activeSection === HotelProfileSection.room && (
                <HotelRooms
                  handleFormSubmiHotelRoom={handleFormSubmiHotelRoom}
                  initialValues={profileData?.roomData}
                  setActiveSection={setActiveSection}
                />
              )}

              {activeSection === HotelProfileSection.package && (
                <HotelPackage
                  packageData={profileData?.packageData}
                  packageColumnData={profileData?.packageColumnData}
                  handleFormSubmiHotelPackage={handleFormSubmiHotelPackage}
                  packageTerms={profileData?.packageTerms}
                />
              )}
            </>
          )}
        </>
      </Drawer>
    </>
  );
};
