import {
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Button,
  Drawer,
  Modal,
  Tag,
  Tooltip,
  Upload,
  Input,
  Form,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Spin,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import VehicleForm from "./Vehicle";
import { Link } from "react-router-dom";
import {
  countries,
  GUIDE_TERMS_AND_CONDITION,
  guideTypes,
  IAdminreviewStatus,
  IMAGE_URL,
  UserRolesEnum,
  YT_TUTORIAL_GUIDE,
} from "../../../src/Constant";
import GalleryImageForm from "./Gallery";
import { HrLine } from "../Hr-line/HrLine";
import AddPackageForm from "./Package";
import { AddGuideBioData, AddGuideStatus, GetGuideBioDataForProfile } from "../../../src/Api";
import { getProfileStatusColor } from "../../../src/lib";
import LanguageForm from "./Language";
import { WhatsApp } from "../Whatsapp/WhatsApp";
import { GuideCalender } from "../Guide-calender/Guide-calender";
import { ProfileIcon } from "../Profile-icon/Profile-icon";

const { TextArea } = Input;
interface LanguageOption {
  language: string;
  level: string;
}

export const GuideProfile = ({ userid }) => {
  const [visible, setVisible] = useState(false);
  const { user } = useKindeAuth();
  const [form] = Form.useForm();
  const [vehicles, setVehicles] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([]);
  const user_role = sessionStorage.getItem("user_role");

  //image handle
  const [profileImage, setProfileImage] = useState([]);
  const [nicImage, setNicImage] = useState([]);
  const [guideIdImage, setGuideIdImage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  //pofile data load
  const [profileData, setProfileData] = useState<any>();

  //admin part
  const [comment, setComment] = useState<string>(
    profileData?.userBioData?.profile_Comment || ""
  );
  const [status, setStatus] = useState<IAdminreviewStatus | null>(null);

  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await GetGuideBioDataForProfile(userid);
      setProfileData(response);
      if (response) {
        if (response?.userBioData.profileImage) {
          setProfileImage([
            {
              uid: "-1",
              name: "profileImage.png",
              status: "done",
              url: `${IMAGE_URL}/upload/${response.userBioData.profileImage}`,
            },
          ]);
        } else {
          setProfileImage([]);
        }

        if (response?.userBioData.nicImage) {
          setNicImage([
            {
              uid: "-2",
              name: "nicImage",
              status: "done",
              url: `${IMAGE_URL}/upload/${response.userBioData.nicImage}`,
            },
          ]);
        } else {
          setNicImage([]);
        }

        if (response?.userBioData.guideIdImage) {
          setGuideIdImage([
            {
              uid: "-3",
              name: "guideIdImage",
              status: "done",
              url: `${IMAGE_URL}/upload/${response.userBioData.guideIdImage}`,
            },
          ]);
        } else {
          setGuideIdImage([]);
        }

        if (response?.userVehicleData) {
          setVehicles(response?.userVehicleData);
        } else {
          setVehicles([]);
        }

        if (response?.userGalleryData) {
          setGalleryImages(response?.userGalleryData);
        } else {
          setGalleryImages([]);
        }

        if (response?.userPackageData) {
          setPackages(response?.userPackageData);
        } else {
          setPackages([]);
        }

        if (response?.userBioData?.languages) {
          setLanguageOptions(response?.userBioData?.languages);
        } else {
          setLanguageOptions([]);
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Somthing went wrong.please try againg later");
    } finally {
      setTimeout(() => setIsLoading(false), 2000);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchProfileData();
    }
  }, [visible]);

  const handleCheckboxChange = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const handleUpload =
    (fileListSetter) =>
    ({ fileList }) => {
      fileListSetter(fileList);
    };

  const imageUploadButton = (text: string) => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {text}</div>
    </div>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  //   Bio section
  const [aboutUs, setAboutUs] = useState("");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleFormSubmit = async (values) => {
    //lang check
    if (languageOptions.length === 0) {
      toast.warning("Please select at least one language.");
      return;
    }
    const incompleteLanguages = languageOptions.filter(
      (lang: any) => !lang.level || lang.level.trim() === ""
    );
    if (incompleteLanguages.length > 0) {
      toast.warning("Please select a proficiency level for each language.");
      return;
    }

    setIsLoading(true);

    const profileData = {
      ...values,
      vehicles,
      galleryImages,
      packages,
      languageOptions,
      userId: userid,
    };

    try {
      const response = await AddGuideBioData(profileData);
      if (response?.isDataSet && response?.isDataAdded) {
        toast.success(response?.msg);
        setVisible(false);
      } else {
        toast.error(response?.msg);
      }
    } catch (e) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setTimeout(() => setIsLoading(false), 3000);
    }
  };

  const handleStatusChange = (value: IAdminreviewStatus) => {
    setStatus(value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAdminReviewAction = async() => {
    if (status && comment) {      
      const data = await AddGuideStatus({status,comment,userid})
      if(data?.isDataAdded){
        toast.success(data?.msg)
        fetchProfileData()
      }
    } else {
      toast.error("Please select a status and ensure the record is available.");
    }
  };

  return (
    <>
      <>
        {user_role === UserRolesEnum.admin ? (
          <Button
            className="bg-blue-500 text-white"
            onClick={() => {
              showDrawer();
            }}
          >
            Check Profile
          </Button>
        ) : (
          <div className="flex justify-stat md:justify-end shadow-lg">
            <ProfileIcon showDrawer={showDrawer} />
            <WhatsApp />
            <GuideCalender />
          </div>
        )}
      </>

      {/* Drawer */}
      <Drawer
        title={
          <div className="hover:cursor-pointer flex justify-between items-center">
            <p className="mb-0">Profile </p>
            <Tooltip
              placement="bottomLeft"
              title={`${
                profileData?.userBioData?.profile_Comment
                  ? profileData?.userBioData?.profile_Comment
                  : "Fill the pofile and make a request"
              }`}
            >
              <Tag
                bordered={false}
                color={getProfileStatusColor(
                  profileData?.userBioData?.Profile_status
                )}
              >
                {profileData?.userBioData?.Profile_status
                  ? profileData?.userBioData?.Profile_status
                  : "Make a Request"}
              </Tag>
            </Tooltip>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        width={600}
      >
        <>
          {/* Bio Data add */}
          {isLoading ? (
            <>
              <div className="flex justify-center flex-col gap-y-10 items-center h-full">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
                <p>Profile data is loading. Please wait a moment.</p>
              </div>
            </>
          ) : (
            <div>
              <Form
                form={form}
                initialValues={profileData?.userBioData}
                layout="vertical"
                onFinish={handleFormSubmit}
              >
                <div>
                  <div className="text-right flex justify-between">
                    <Link
                      className="underline text-blue-500"
                      target="_blank"
                      to={GUIDE_TERMS_AND_CONDITION}
                    >
                      Terms and condition
                    </Link>
                    <Link
                      className="underline text-blue-500"
                      target="_blank"
                      to={YT_TUTORIAL_GUIDE}
                    >
                      Instruction
                    </Link>
                    <p className="font-bold">
                      <span className="text-red-600">*</span> All fields are
                      required
                    </p>
                  </div>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Profile Image"
                        name="profileImage"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your profile image",
                          },
                        ]}
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={profileImage}
                          onChange={handleUpload(setProfileImage)}
                          beforeUpload={beforeUpload}
                          onPreview={handlePreview}
                        >
                          {profileImage.length >= 1
                            ? null
                            : imageUploadButton("Profile image")}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="NIC Image"
                        name="nicImage"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Nic image",
                          },
                        ]}
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={nicImage}
                          onChange={handleUpload(setNicImage)}
                          beforeUpload={beforeUpload}
                          onPreview={handlePreview}
                        >
                          {nicImage.length >= 1
                            ? null
                            : imageUploadButton("nic image")}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="GuideID Image"
                        name="guideIdImage"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Guide id image",
                          },
                        ]}
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={guideIdImage}
                          onChange={handleUpload(setGuideIdImage)}
                          beforeUpload={beforeUpload}
                          onPreview={handlePreview}
                        >
                          {guideIdImage.length >= 1
                            ? null
                            : imageUploadButton("guideid image")}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>

                  <Form.Item
                    label="Guide ID"
                    name="guideId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Guideid number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your guide ID" />
                  </Form.Item>

                  <Form.Item
                    label="Contact Number"
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your contact number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your contact number" />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item
                    label="Personal Address"
                    name="personalAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your personal address",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your personal address" />
                  </Form.Item>

                  <Form.Item
                    label="Short Description"
                    name="shortDescription"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter a short description about your service",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      placeholder="Enter a short description about your service"
                    />
                  </Form.Item>

                  <Form.Item
                    label="About You"
                    name="aboutYou"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter a description about you and your service",
                      },
                    ]}
                  >
                    <ReactQuill
                      value={aboutUs}
                      onChange={setAboutUs}
                      placeholder="Tell us about yourself"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Experienced Year"
                    name="experiencedYear"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your experienced years",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter your experienced years"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Total Tours"
                    name="totalTours"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your total tours conducted",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter total tours conducted"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Rate ($) (Per day)"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your rate in dollars",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter your rate in dollars"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please select your country",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your country"
                      style={{ width: "100%" }}
                    >
                      {countries.map((country) => (
                        <Select.Option
                          key={country.value}
                          value={country.value}
                        >
                          {country.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Guide type"
                    name="guidetype"
                    rules={[
                      {
                        required: true,
                        message: "Please select your type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your guide type"
                      style={{ width: "100%" }}
                    >
                      {guideTypes.map((type) => (
                        <Select.Option key={type.value} value={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <LanguageForm
                    languageOptions={languageOptions}
                    setLanguageOptions={setLanguageOptions}
                  />
                </div>

                <HrLine text={"Vehicle section"} />

                <VehicleForm vehicles={vehicles} setVehicles={setVehicles} />

                <HrLine text={"Gallery section"} />

                <GalleryImageForm
                  images={galleryImages}
                  setImages={setGalleryImages}
                />

                <HrLine text={"Tour package section"} />

                <AddPackageForm packages={packages} setPackages={setPackages} />

                <Form.Item
                  name="acceptTerms"
                  valuePropName="checked"
                  className="my-5"
                >
                  <Checkbox onChange={handleCheckboxChange}>
                    I accept the terms and conditions
                  </Checkbox>
                </Form.Item>

                <Form.Item className="text-end my-[5vh]">
                  <Button
                    type="default"
                    htmlType="submit"
                    disabled={!isTermsAccepted}
                  >
                    Send a profile review request
                  </Button>
                </Form.Item>
              </Form>

              {user_role === UserRolesEnum.admin && (
                <>
                  <div className="p-4">
                    <div className="admin-review">
                      <h3 className="text-xl font-semibold mb-2">
                        Admin Review
                      </h3>
                      <>
                        <div className="text-end">
                          <Space size="middle">
                            <Select
                              className="w-full text-white"
                              placeholder="Select an action"
                              onChange={handleStatusChange}
                              value={
                                status ||
                                profileData?.userBioData?.Profile_status
                              }
                            >
                              <Select.Option
                                value={IAdminreviewStatus.accepted}
                              >
                                {IAdminreviewStatus.accepted}
                              </Select.Option>
                              <Select.Option
                                value={IAdminreviewStatus.rejected}
                              >
                                {IAdminreviewStatus.rejected}
                              </Select.Option>
                              <Select.Option value={IAdminreviewStatus.blocked}>
                                {IAdminreviewStatus.blocked}
                              </Select.Option>
                            </Select>
                          </Space>
                        </div>
                        <Input.TextArea
                          className="w-full mb-2 border border-gray-300 rounded p-2"
                          placeholder="Write your comment here..."
                          value={
                            comment || profileData?.userBioData?.profile_Comment
                          }
                          onChange={handleCommentChange}
                          rows={4}
                        />
                        <span>
                          <ul>
                            <li className="my-2">
                              {" "}
                              <strong>Accepted : </strong>The profile has been
                              accepted based on the review criteria.
                            </li>
                            <li className="my-2">
                              {" "}
                              <strong>Reject : </strong> The profile has been
                              rejected due to not meeting the required criteria.
                            </li>
                            <li className="my-2">
                              <strong>Block : </strong>The profile has been
                              blocked for further review or due to specific
                              reasons.
                            </li>
                          </ul>
                        </span>
                        <Button
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                          onClick={()=>{handleAdminReviewAction()}}
                        >
                          Submit Review
                        </Button>
                      </>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      </Drawer>

      {/*image privew*/}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
