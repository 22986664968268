import { toast } from "react-toastify";
import axios from "axios";
import { SERVER_API } from "../../Constant";

export const GetHotelDataForProfile  = async (kd_id:any) => {
  try {
    const response = await axios.get(
      `${SERVER_API}/Hotel/get_hotel_profile_data.php?kd_id=${kd_id}`
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong");
  }
};
