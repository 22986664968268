import {
  Col,
  Row,
  Form,
  Upload,
  Input,
  Select,
  Radio,
  Checkbox,
  Button,
  Modal,
} from "antd";
import {
  GUIDE_TERMS_AND_CONDITION,
  IMAGE_URL,
  YT_TUTORIAL_GUIDE,
} from "../../../Constant";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UniqueSellingPointsForm } from "../../Unique-Selling-Points-Form/UniqueSellingPointsForm";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { GetHotelFacilityData } from "../../../Api";

export const GeneralHotelProfileData = ({
  handleFormSubmitGenaralData,
  initialValues,
  usps,
  setUSPs,
}) => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState([]);
  const [lisenceImage, setLisenceImage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  //all items
  const [category, setCategory] = useState<any>([]);
  const [generalFacility, setGeneralFacility] = useState<any>([]);
  const [dineInOptions, setDineInOptions] = useState<any>([]);
  const [specialServices, setSpecialServices] = useState<any>([]);

  const [selectedDiningOptions, setSelectedDiningOptions] = useState([]);
  const [selectedSpecialServices, setSelectedSpecialServices] = useState([]);

  const handleUpload =
    (fileListSetter) =>
    ({ fileList }) => {
      fileListSetter(fileList);
    };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleCancel = () => setPreviewVisible(false);

  const imageUploadButton = (text: string) => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {text}</div>
    </div>
  );

  const fetchFacilityData = async () => {
    const data = await GetHotelFacilityData();

    setCategory(data?.facilityTableData?.hotel_category);
    setGeneralFacility(data?.facilityTableData?.general_facilities);
    setDineInOptions(data?.facilityTableData?.dining_options);
    setSpecialServices(data?.facilityTableData?.special_services);

    if (initialValues) {
      setUSPs(initialValues.usps || "[]");

      // Populate file lists
      if (initialValues.profileImage) {
        setProfileImage([
          {
            uid: "-1",
            name: "profileImage.png",
            status: "done",
            url: `${IMAGE_URL}/upload/${initialValues.profileImage}`,
          },
        ]);
      } else {
        setProfileImage([]);
      }

      if (initialValues.licenceImage) {
        setLisenceImage([
          {
            uid: "-1",
            name: "licenceImage.png",
            status: "done",
            url: `${IMAGE_URL}/upload/${initialValues.licenceImage}`,
          },
        ]);
      } else {
        setLisenceImage([]);
      }
    }
  };

  useEffect(() => {
    fetchFacilityData();
  }, []);

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleFormSubmitGenaralData}
      >
        <div>
          <div className="text-right flex justify-between">
            <Link
              className="underline text-blue-500"
              target="_blank"
              to={GUIDE_TERMS_AND_CONDITION}
            >
              Terms and condition
            </Link>
            <Link
              className="underline text-blue-500"
              target="_blank"
              to={YT_TUTORIAL_GUIDE}
            >
              Instruction
            </Link>
            <p className="font-bold">
              <span className="text-red-600">*</span> All fields are required
            </p>
          </div>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Profile Image"
                name="profileImage"
                rules={[
                  {
                    required: true,
                    message: "Please enter your profile image",
                  },
                ]}
              >
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={profileImage}
                  onChange={handleUpload(setProfileImage)}
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                >
                  {profileImage.length >= 1
                    ? null
                    : imageUploadButton("Profile image")}
                </Upload>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Licence Image"
                name="licenceImage"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Licence image",
                  },
                ]}
              >
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={lisenceImage}
                  onChange={handleUpload(setLisenceImage)}
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                >
                  {lisenceImage.length >= 1
                    ? null
                    : imageUploadButton("Lisence image")}
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          {/* Hotel Name */}
          <Form.Item
            label="Hotel Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the official name of the hotel",
              },
            ]}
          >
            <Input placeholder="Enter hotel name" />
          </Form.Item>

          {/* Location */}
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please enter the full address of the hotel",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Enter full address including city and province"
              rows={3}
            />
          </Form.Item>

          {/* Google Maps Link */}
          <Form.Item
            label="Map Link"
            name="mapLink"
            rules={[
              {
                type: "url",
                required: true,
                message: "Please enter a valid Google Maps URL",
              },
            ]}
          >
            <Input placeholder="Paste Google Maps link here" />
          </Form.Item>

          {/* Contact Information */}
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please enter a contact phone number",
              },
            ]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>

          {/* Star Rating */}
          <Form.Item
            label="Star Rating"
            name="rating"
            rules={[
              {
                required: true,
                message: "Please select the star rating of the hotel",
              },
            ]}
          >
            <Select placeholder="Select star rating">
              <Select.Option value="3">3 Stars</Select.Option>
              <Select.Option value="4">4 Stars</Select.Option>
              <Select.Option value="5">5 Stars</Select.Option>
            </Select>
          </Form.Item>

          {/* Sri Lankan Districts */}
          <Form.Item
            label="District"
            name="district"
            rules={[
              {
                required: true,
                message: "Please select a district",
              },
            ]}
          >
            <Select placeholder="Select a district">
              <Select.Option value="Ampara">Ampara</Select.Option>
              <Select.Option value="Anuradhapura">Anuradhapura</Select.Option>
              <Select.Option value="Badulla">Badulla</Select.Option>
              <Select.Option value="Batticaloa">Batticaloa</Select.Option>
              <Select.Option value="Colombo">Colombo</Select.Option>
              <Select.Option value="Galle">Galle</Select.Option>
              <Select.Option value="Gampaha">Gampaha</Select.Option>
              <Select.Option value="Hambantota">Hambantota</Select.Option>
              <Select.Option value="Jaffna">Jaffna</Select.Option>
              <Select.Option value="Kalutara">Kalutara</Select.Option>
              <Select.Option value="Kandy">Kandy</Select.Option>
              <Select.Option value="Kegalle">Kegalle</Select.Option>
              <Select.Option value="Kilinochchi">Kilinochchi</Select.Option>
              <Select.Option value="Kurunegala">Kurunegala</Select.Option>
              <Select.Option value="Mannar">Mannar</Select.Option>
              <Select.Option value="Matale">Matale</Select.Option>
              <Select.Option value="Matara">Matara</Select.Option>
              <Select.Option value="Monaragala">Monaragala</Select.Option>
              <Select.Option value="Mullaitivu">Mullaitivu</Select.Option>
              <Select.Option value="Nuwara Eliya">Nuwara Eliya</Select.Option>
              <Select.Option value="Polonnaruwa">Polonnaruwa</Select.Option>
              <Select.Option value="Puttalam">Puttalam</Select.Option>
              <Select.Option value="Ratnapura">Ratnapura</Select.Option>
              <Select.Option value="Trincomalee">Trincomalee</Select.Option>
              <Select.Option value="Vavuniya">Vavuniya</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Hotel Type"
            name="hotelType"
            rules={[
              {
                required: true,
                message: "Please select the hotel type",
              },
            ]}
          >
            <Radio.Group className="flex custome-radio-btn-hotel-profile flex-wrap gap-[15px]">
              {category?.map((category: any) => (
                <Radio key={category?.id} value={category?.id}>
                  {category?.category.replace(/_/g, " ").toUpperCase()}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* Description Section */}
          <Form.Item
            label="Overview"
            name="overview"
            rules={[
              {
                required: true,
                message: "Please provide a brief description of the hotel",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Describe the hotel, its theme, and target audience (e.g., luxury travelers, families, budget travelers)"
              rows={4}
            />
          </Form.Item>

          <UniqueSellingPointsForm usps={usps} setUSPs={setUSPs} />

          {/* Facilities and Services */}
          <Form.Item
            className="mt-5"
            label="General and Services"
            name="generalfacilities"
          >
            <Checkbox.Group
              className="flex custome-checkbox-btn-hotel-profile flex-wrap gap-[15px]"
              value={initialValues.generalfacilities}
            >
              {generalFacility?.length > 0 ? (
                generalFacility.map(
                  (option: { id: number; facilities: string }) => (
                    <Checkbox key={option.id} value={String(option.id)}>
                      {option.facilities.replace(/_/g, " ").toUpperCase()}
                    </Checkbox>
                  )
                )
              ) : (
                <p>No facilities available</p>
              )}
            </Checkbox.Group>
          </Form.Item>

          {/* Dining Options */}
          <Form.Item
            className="mt-5"
            label="Dining Options"
            name="diningoption"
          >
            <Checkbox.Group
              value={initialValues.diningoption}
              className="flex custome-checkbox-btn-hotel-profile flex-wrap gap-[15px]"
            >
              {dineInOptions?.map((option: any) => (
                <Checkbox key={option?.id} value={String(option?.id)}>
                  {option?.facilities.replace(/_/g, " ").toUpperCase()}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>

          {/* Special Services */}
          <Form.Item
            className="mt-5"
            label="Special Services"
            name="specialservice"
          >
            <Checkbox.Group
              value={initialValues.specialservice}
              className="flex custome-checkbox-btn-hotel-profile flex-wrap gap-[15px]"
            >
              {specialServices?.map((option: any) => (
                <Checkbox key={option?.id} value={String(option?.id)}>
                  {option?.facilities.replace(/_/g, " ").toUpperCase()}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>

        <Form.Item className="mt-4 text-end mb-2">
          <Button type="primary" htmlType="submit">
            Save Profile and Next
          </Button>
        </Form.Item>
      </Form>

      {/*image privew*/}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
